import { useState } from 'react';

function Contact(props) {

  const [text] = useState("");

  return (
    <div className="untree_co-section" id="contact-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-7 text-center mx-auto">
            <h2 className="section-heading gsap-reveal-hero mb-0"><strong>Contact</strong></h2>
            <p className="gsap-reveal-hero">For any query. Get in touch.</p>
            <div className="wave gsap-reveal-hero">
              <svg>
                <path d="M10,10 L50,100 L90,50"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1 col-md-2 col-lg-3"></div>
          <div className="col-sm-10 col-md-8 col-lg-6">
            <form onSubmit={e => e.preventDefault()}>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <input value={text} onChange={() => { }} type="text" className="form-control" placeholder="Firstname" />
                </div>
                <div className="col-lg-6 form-group">
                  <input value={text} onChange={() => { }} type="text" className="form-control" placeholder="Lastname" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 form-group">
                  <input value={text} onChange={() => { }} type="email" className="form-control" placeholder="Email address" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 form-group">
                  <textarea value={text} onChange={() => { }} name="" id="" cols="30" rows="7" className="form-control"
                    placeholder="Write your message..."></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group">
                  <input disabled type="submit" className="btn btn-black" value="Send Message" />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-1 col-md-2 col-lg-3"></div>
        </div>
      </div>
    </div>
  )
}

export default Contact;