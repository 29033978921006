function Home(props) {

  return (
    <div className="untree_co-section pb-0" id="home-section">
      <div className="container">
        <h1 className="heading gsap-reveal-hero mb-0">Hey! I'm <strong>Samiul Alim</strong>.</h1>
        <h2 className="subheading gsap-reveal-hero mb-4">
          Welcome to my personal website. It's a minimal informative website about me :)
        </h2>
      </div>
    </div>
  )
}

export default Home;