import { useEffect } from "react";
import Home from "./components/home";
// import Portfolio from "./components/portfolio";
// import Services from "./components/services";
import About from "./components/about";
// import Skills from "./components/skills";
// import Testimonials from "./components/testimonials";
// import Blogs from "./components/blogs";
import Contact from "./components/contact";
import ThankYou from "./components/thank_you";


function App() {

  return (
    <div>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close">
            <span className="icofont-close js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <nav className="site-nav dark mb-5 site-navbar-target">
        <div className="container">
          <div className="site-navigation">
            <a href="index-2.html" className="logo m-0">Samiul<span className="text-primary">.</span></a>
            <ul className="js-clone-nav d-none d-lg-inline-none site-menu float-right site-nav-wrap">
              <li><a href="#home-section" className="nav-link active">Home</a></li>
              <li><a href="#about-section" className="nav-link">About</a></li>
              {/* <li><a href="#portfolio-section" className="nav-link">Portfolio</a></li> */}
              {/* <li><a href="#services-section" className="nav-link">Services</a></li> */}
              <li><a href="#contact-section" className="nav-link">Contact</a></li>
            </ul>
            <a href="#" className="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-inline-block"
              data-toggle="collapse" data-target="#main-navbar">
              <span></span>
            </a>
          </div>
        </div>
      </nav>

      <Home />
      <About />
      {/* <Skills /> */}
      {/* <Services /> */}
      {/* <Portfolio /> */}
      {/* <Testimonials /> */}
      {/* <Blogs /> */}
      <Contact />
      <ThankYou />

      <div className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 pull-right">
              <div className="widget">
                <h3>Connect</h3>
                <ul className="list-unstyled social">
                  <li><a target="_blank" href="https://bd.linkedin.com/in/samiul-alim"><span className="icon-linkedin"></span></a></li>
                  <li><a target="_blank" href="https://github.com/samiul-alim"><span className="icon-github"></span></a></li>
                  <li><a target="_blank" href="https://facebook.com/msa.shawon"><span className="icon-facebook"></span></a></li>
                  <li><a target="_blank" href="https://twitter.com/msa_shawon"><span className="icon-twitter"></span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <p> Copyright &copy;{new Date().getFullYear()} All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      <div id="overlayer"></div>
      <div className="loader">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default App;
