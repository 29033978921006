
function ThankYou(props) {

  return (
    // <div className="untree_co-section" id="contact-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-7 text-center mx-auto">
            <h2 className="section-heading gsap-reveal-hero mb-0"><strong></strong></h2>
            <p className="gsap-reveal-hero">Thank you for visiting!</p>
            <div className="wave gsap-reveal-hero">
              <svg>
                <path d="M10,10 L50,100 L90,50"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    // </div>
  )
}

export default ThankYou;