
function About(props) {


  return (
    <div className="untree_co-section pb-0" id="about-section">
      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-6 text-left">
            <h2 className="section-heading gsap-reveal-hero mb-0"><strong>About</strong></h2>
            <p className="gsap-reveal-hero">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            <div className="wave gsap-reveal-hero">
              <svg>
                <path d="M10,10 L50,100 L90,50"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 position-relative">
            <div className="experience-wrap gsap-reveal-hero">
              <div className="experience">
                <span className="d-block">{new Date().getFullYear() - 2016} Years of experience</span>
                <a href="#" onClick={e => e.preventDefault()}> <span className="icon-arrow_forward"></span> Full stack developer</a>
                <a href="#" onClick={e => e.preventDefault()}><span className="icon-arrow_forward"></span> Python, Javascript</a>
              </div>
            </div>
            <figure className="dotted-bg gsap-reveal-img">
              <img src="images/about_pic1.jpg" alt="Image" className="img-fluid" />
            </figure>
          </div>
          <div className="col-lg-6 pl-md-5">
            <h3 className="text-black h5 font-weight-bold mb-3 gsap-reveal-hero">Bio</h3>
            <p className="gsap-reveal-hero">
              My full name is Md. Samiul Alim (Shawon). I born and raised in Nesarabad(Swarupkathi), Pirojpur. I started my institutional education in a local kindergarten. Later I passed secondary and higher secondary from a local school and college. Then I went to Dhaka and graduated with a Software Engineer B.Sc degree from American International University - Bangladesh(AIUB). I am enthusiastic about sports and played a lot before I left Swarupkathi. Besides, I love to travel natural beauty a lot.
            </p>
            <p className="gsap-reveal-hero ">I started my professional career as an intern at Workspace Infotech in 2016 and later I continued as a Junior Software Engineer then Software Engineer till October 2020. I joined Brain Station 23 as a Senior Software Engineer in October 2020.</p>
            <div className="row my-5">
              <div className="col-12">
                <h3 className="text-black h5 font-weight-bold mb-3 gsap-reveal-hero">Work Experience</h3>
              </div>
              <div className="col-md-12">
                <div className="work-experience gsap-reveal-hero" style={{ marginLeft: 40 }}>
                  <h3 className="position">Senior Software Engineer</h3>
                  <span className="company">Brain Station 23</span>
                  <span className="year">2020 - continuing</span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="work-experience gsap-reveal-hero" style={{ marginLeft: 40 }}>
                  <h3 className="position">Software Engineer</h3>
                  <span className="company">Workspace Infotech Ltd.</span>
                  <span className="year">2016 - 2020</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;